import React from 'react';

import { GroupConfig } from '../../lib/types';

function AddGroupConfig({
    onAddGroupConfig,
    groups,
}: {
    onAddGroupConfig: (name: string) => void;
    groups: GroupConfig[];
}) {
    const [name, setName] = React.useState('');

    function handleAddGroupFormSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const groupNames = groups.map(gr => gr.name);

        let newName = name;
        if (!newName) {
            let c = groups.length + 1;
            newName = `Group ${c}`;
            while (groupNames.includes(newName)) {
                c += 1;
                newName = `Group ${c}`;
            }
        }

        if (newName.startsWith('__') || newName.endsWith('__')) {
            alert('Wrong name! (can not start or end with "__")');
            return;
        }

        if (groupNames.includes(newName)) {
            alert('Name duplicated!');
            return;
        }

        onAddGroupConfig(newName);
        setName('');
    }

    return (
        <form onSubmit={handleAddGroupFormSubmit}>
            <input placeholder="type config name..." value={name} onChange={event => setName(event.target.value)} />{' '}
            <button type="submit">Add new config</button>
        </form>
    );
}

export default AddGroupConfig;
