import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';

function booleanCleaner(value: string | number | boolean) {
    if (['true', 'yes', 'ja', 1, true].includes(value)) {
        return true;
    } else if (['false', 'no', 'nein', 0, false].includes(value)) {
        return false;
    } else {
        return null;
    }
}

function BooleanCellRenderer(props: ICellRendererParams) {
    const valueCleaned = booleanCleaner(props.value);

    if (valueCleaned === true) {
        return <span title="true" className="ag-icon ag-icon-tick content-icon"></span>;
    } else if (valueCleaned === false) {
        return <span title="false" className="ag-icon ag-icon-cross content-icon"></span>;
    } else if (props.value !== null && props.value !== undefined) {
        return props.value.toString();
    } else {
        return null;
    }
}

export default BooleanCellRenderer;
