import React from 'react';

import { GroupConfig } from '../../lib/types';

function ManageGroupConfig({
    group,
    onGroupChanged,
}: {
    group: GroupConfig;
    onGroupChanged: (group: GroupConfig) => void;
}) {
    const [searchTerm, setSearchTerm] = React.useState('');
    const [selectedCols, setSelectedCols] = React.useState<string[]>([]);

    const [newColGroupName, setNewColGroupName] = React.useState('');

    const filteredCols = group.columns.filter(
        col => !searchTerm || col.headerName?.toLocaleLowerCase().includes(searchTerm)
    );

    const groupNames = Array.from(new Set(group.columns.map(col => col.parentName).filter(name => !!name)));

    function assignParent(name: string) {
        const columns = group.columns.map(col =>
            selectedCols.includes(col.headerName) ? { ...col, parentName: name } : { ...col }
        );
        onGroupChanged({ ...group, columns });
        setSelectedCols([]);
    }

    return (
        <>
            <hr />
            <strong>Managing "{group.name}"</strong>

            <table>
                <tbody>
                    <tr>
                        <td>Assign group:</td>
                        <td>
                            <select
                                value=""
                                onChange={event => {
                                    assignParent(event.target.value);
                                }}
                            >
                                <option value="">- select group -</option>
                                {groupNames.map(gn => (
                                    <option value={gn} key={gn}>
                                        {gn}
                                    </option>
                                ))}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>Add new group:</td>
                        <td>
                            <form
                                onSubmit={event => {
                                    event.preventDefault();
                                    if (!newColGroupName) {
                                        alert('Enter name!');
                                        return;
                                    }

                                    if (groupNames.includes(newColGroupName)) {
                                        alert('Name duplicated!');
                                        return;
                                    }

                                    assignParent(newColGroupName);
                                    setNewColGroupName('');
                                }}
                            >
                                <input
                                    placeholder="type group name..."
                                    value={newColGroupName}
                                    onChange={event => setNewColGroupName(event.target.value)}
                                />{' '}
                                <button type="submit">add new group</button>
                            </form>
                        </td>
                    </tr>
                    <tr>
                        <td>No group:</td>
                        <td>
                            <button
                                onClick={() => {
                                    const columns = group.columns.map(col =>
                                        selectedCols.includes(col.headerName)
                                            ? { ...col, parentName: undefined }
                                            : { ...col }
                                    );
                                    onGroupChanged({ ...group, columns });
                                    setSelectedCols([]);
                                }}
                            >
                                ungroup
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <hr />
            <input
                type="checkbox"
                checked={filteredCols.every(col => selectedCols.includes(col.headerName))}
                onChange={event => {
                    if (event.target.checked) {
                        setSelectedCols(filteredCols.map(col => col.headerName));
                    } else {
                        setSelectedCols([]);
                    }
                }}
            />
            <input
                placeholder="Search..."
                value={searchTerm}
                onChange={evt => {
                    setSearchTerm(evt.target.value.toLowerCase());
                    if (selectedCols.length > 0) {
                        const resultCols = filteredCols.map(col => col.headerName);
                        setSelectedCols(selectedCols.filter(col => resultCols.includes(col)));
                    }
                }}
            />

            <ul style={{ listStyle: 'none', padding: 0 }}>
                {filteredCols
                    .filter(col => !col.parentName)
                    .map(col => (
                        <li key={col.headerName}>
                            <input
                                checked={selectedCols.includes(col.headerName)}
                                type="checkbox"
                                value={col.headerName}
                                onChange={event => {
                                    if (event.target.checked) {
                                        setSelectedCols([...selectedCols, event.target.value]);
                                    } else {
                                        setSelectedCols(selectedCols.filter(col => col !== event.target.value));
                                    }
                                }}
                            />
                            {col.headerName}
                        </li>
                    ))}

                {groupNames.sort().map(parentName => {
                    const groupCols = filteredCols.filter(col => col.parentName === parentName);
                    const colNames = groupCols.map(col => col.headerName);

                    if (groupCols.length === 0) {
                        return null;
                    }

                    return (
                        <li key={parentName}>
                            <input
                                checked={groupCols.every(nn => selectedCols.includes(nn.headerName))}
                                type="checkbox"
                                onChange={event => {
                                    if (event.target.checked) {
                                        setSelectedCols([...selectedCols, ...colNames]);
                                    } else {
                                        setSelectedCols(selectedCols.filter(col => !colNames.includes(col)));
                                    }
                                }}
                            />
                            {parentName}
                            <ul style={{ listStyle: 'none', paddingInlineStart: '20px' }}>
                                {groupCols.map(col => (
                                    <li key={col.headerName}>
                                        <input
                                            checked={selectedCols.includes(col.headerName)}
                                            type="checkbox"
                                            value={col.headerName}
                                            onChange={event => {
                                                if (event.target.checked) {
                                                    setSelectedCols([...selectedCols, event.target.value]);
                                                } else {
                                                    setSelectedCols(
                                                        selectedCols.filter(col => col !== event.target.value)
                                                    );
                                                }
                                            }}
                                        />
                                        {col.headerName}
                                    </li>
                                ))}
                            </ul>
                        </li>
                    );
                })}
            </ul>
        </>
    );
}

export default ManageGroupConfig;
