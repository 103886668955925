import React from 'react';
import { GridApi } from 'ag-grid-community';

function SQLColumnsPanel({ apiUrl, gridApi }: { apiUrl: string; gridApi: GridApi }) {
    const [sql, setSQL] = React.useState('row % 10 == 0');
    const [column, setColumn] = React.useState('col1');
    const [type, setType] = React.useState('none');
    const NO_RESULT = { error: false, message: '' };
    const [result, setResult] = React.useState(NO_RESULT);

    async function executeSQL(apiUrl: string, endpoint: string, sql: object) {
        const t = type;
        setType('none');
        const response = await fetch(`${apiUrl}/${endpoint}`, {
            method: 'POST',
            body: JSON.stringify(sql),
            headers: { 'Content-Type': 'application/json' },
        });

        if (!response.ok) {
            console.log('Error while executing SQL: ');
            console.log(`Error ${response.status}: ${response.statusText}`);
            const text = await response.text();
            const message = `Error ${response.status}: ${response.statusText}:\n${text}`;
            const result = {
                error: true,
                message: message,
            };
            setResult(result);
            // allow the user to correct the mistake...
            setType(t);
        } else {
            let message = await response.json();
            // noinspection SuspiciousTypeOfGuard
            if (typeof message !== 'string') {
                message = JSON.stringify(message);
            }
            setResult({ error: false, message });
        }
        gridApi.refreshServerSideStore({ purge: true });
    }
    function handleAddLayoutFormSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const endpoint = type === 'ExecuteSQL' ? 'execute-sql' : 'add-column-sql';
        executeSQL(apiUrl, endpoint, { column, sql, type }).catch(err => console.error(err));
    }
    function onColumnChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setColumn(event.target.value);
        setResult(NO_RESULT);
    }
    function onTypeChanged(event: React.ChangeEvent<HTMLSelectElement>) {
        setType(event.target.value);
        setResult(NO_RESULT);
    }
    function onSQLhanged(event: React.ChangeEvent<HTMLTextAreaElement>) {
        setSQL(event.target.value);
        setResult(NO_RESULT);
    }
    let message = result.message;
    try {
        message = JSON.stringify(JSON.parse(message), null, 2);
    } catch {}
    return (
        <form onSubmit={handleAddLayoutFormSubmit}>
            <h3>SQL Query</h3>
            Action:{' '}
            <select value={type} onChange={onTypeChanged}>
                <option value="none">Select...</option>
                <option disabled={true}>__________</option>
                <option value="TEXT">Add TEXT Column</option>
                <option value="INT">Add INT Column</option>
                <option value="FLOAT">Add FLOAT Column</option>
                <option value="BOOL">Add BOOL Column</option>
                <option disabled={true}>__________</option>
                <option value="ExecuteSQL">Execute SQL</option>
            </select>
            {type !== 'ExecuteSQL' && type !== 'none' && (
                <>
                    <br />
                    Add/Update Column:{' '}
                    <input
                        disabled={type === 'none'}
                        placeholder="new column"
                        value={column}
                        onChange={onColumnChanged}
                    />
                </>
            )}
            <br />
            <textarea disabled={type === 'none'} rows={30} value={sql} onChange={onSQLhanged} />
            <br />
            <button disabled={type === 'none'}>Run</button>
            {result.message && (
                <div>
                    <h3>{result.error ? 'Error' : 'Result'}</h3>

                    <pre style={{ color: result.error ? '#CC0000' : undefined }}>{message}</pre>
                </div>
            )}
        </form>
    );
}

export default SQLColumnsPanel;
