import React from 'react';
import { ColGroupDef, ColumnApi } from 'ag-grid-community';
import groupBy from 'lodash/groupBy';
import pick from 'lodash/pick';

import { ColDef, GroupConfig } from '../../lib/types';
import '../../css/ag-grid-panel.css';
import AddGroupConfig from './AddGroupConfig';
import ManageGroupConfig from './ManageGroupConfig';

type GeneralGroupConfig = {
    activeGroup: string;
};

async function saveGroupConfig(apiUrl: string, changedGroup: GroupConfig, isDeleted = false) {
    // save only "headerName" and "parentName" from columns other properties are unnecessary
    changedGroup.columns = (changedGroup.columns || []).map(col => pick(col, 'headerName', 'parentName'));
    const response = await fetch(`${apiUrl}/save-ag-grid-config`, {
        method: 'POST',
        body: JSON.stringify({ type: 'group', group: changedGroup, isDeleted }),
        headers: { 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
        console.log('Error while saving group config: ');
        console.log(`Error ${response.status}: ${response.statusText}`);
        return;
    }
}

async function saveGeneralGroupConfig(apiUrl: string, changedConfig: GeneralGroupConfig) {
    const response = await fetch(`${apiUrl}/save-ag-grid-config`, {
        method: 'POST',
        body: JSON.stringify({ type: 'group_general_config', general_config: changedConfig }),
        headers: { 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
        console.log('Error while saving general group config: ');
        console.log(`Error ${response.status}: ${response.statusText}`);
        return;
    }
}

function activateGroup(
    group: GroupConfig,
    onColumnsChanged: (columns: (ColDef | ColGroupDef)[]) => void,
    gridColumnApi: ColumnApi
) {
    const columnsState = gridColumnApi.getColumnState();
    const groupedByParentName = groupBy(group.columns, 'parentName');
    const newColumns: ColGroupDef[] = [];

    Object.entries(groupedByParentName)
        .filter(([k, _v]) => k !== 'undefined')
        .forEach(([name, cols]) => {
            const children = cols.map(col => ({
                ...col,
                ...(columnsState.find(cs => cs.colId === col.colId) || {}),
                columnGroupShow: 'open',
            })) as ColDef[];
            delete children[0].columnGroupShow;
            newColumns.push({ headerName: name, children });
        });

    const groupedColumns = [
        ...newColumns,
        ...((groupedByParentName['undefined'] || []).map(col => ({
            ...col,
            ...(columnsState.find(cs => cs.colId === col.colId) || {}),
        })) as ColDef[]),
    ];
    groupedColumns.sort((a, b) => {
        let aPos = group.columns.findIndex(col => col.headerName === a.headerName);
        let bPos = group.columns.findIndex(col => col.headerName === b.headerName);
        if ('children' in a) {
            aPos = Math.min(
                ...a.children
                    .map(child => group.columns.findIndex(col => col.headerName === child.headerName))
                    .filter(p => p > -1)
            );
        }
        if ('children' in b) {
            bPos = Math.min(
                ...b.children
                    .map(child => group.columns.findIndex(col => col.headerName === child.headerName))
                    .filter(p => p > -1)
            );
        }

        return aPos > bPos ? 1 : -1;
    });

    onColumnsChanged(groupedColumns);
}

function GroupPanel({
    columns,
    onColumnsChanged,
    apiUrl,
    gridColumnApi,
}: {
    columns: ColDef[];
    onColumnsChanged: (columns: (ColDef | ColGroupDef)[]) => void;
    apiUrl: string;
    gridColumnApi: ColumnApi;
}) {
    const [groups, setGroups] = React.useState<GroupConfig[]>([]);
    const [config, setConfig] = React.useState<GeneralGroupConfig>({ activeGroup: '' });
    const containerEl = React.useRef<HTMLDivElement | null>(null);

    async function handleGroupConfigChange(changedGroup: GroupConfig, idx: number) {
        if (config.activeGroup === changedGroup.name) {
            activateGroup(changedGroup, onColumnsChanged, gridColumnApi);
        }

        setGroups([...groups.slice(0, idx), changedGroup, ...groups.slice(idx + 1)]);

        saveGroupConfig(apiUrl, changedGroup).catch(err => console.log('Error while saving group config:', err));
    }

    function onActivateGroup(group: GroupConfig) {
        activateGroup(group, onColumnsChanged, gridColumnApi);
        const changedConfig = { ...config, activeGroup: group.name };
        setConfig(changedConfig);
        saveGeneralGroupConfig(apiUrl, changedConfig).catch(err =>
            console.log('Error while saving general group config:', err)
        );
    }

    function onDeactivateGroup() {
        const columnsState = gridColumnApi.getColumnState();
        onColumnsChanged(
            columns.map(col => ({
                ...col,
                ...(columnsState.find(cs => cs.colId === col.colId) || {}),
            })) as ColDef[]
        );

        const changedConfig = { ...config, activeGroup: '' };
        setConfig(changedConfig);
        saveGeneralGroupConfig(apiUrl, changedConfig).catch(err =>
            console.log('Error while saving general group config:', err)
        );
    }

    function onDeleteGroup(group: GroupConfig) {
        if (!window.confirm('Are you sure?')) {
            return;
        }

        const deletedGroup = groups.find(gr => gr.name === group.name);
        if (deletedGroup) {
            saveGroupConfig(apiUrl, deletedGroup, true).catch(err =>
                console.log('Error while saving group config:', err)
            );
        }

        setGroups(groups.filter(gr => gr.name !== group.name));
    }

    React.useEffect(() => {
        const columnsWithParentName = columns.map(col => {
            let derivedColumn = (col.groups || []).find(gr => gr.startsWith('__derived_from__'));
            if (!derivedColumn && col.headerName.startsWith('*')) {
                const name = col.headerName.replace(/^\*+/, '');
                const parentCol = columns.find(coll => name.startsWith(coll.headerName));
                if (parentCol) {
                    derivedColumn = parentCol.headerName;
                }
            }
            return {
                ...col,
                parentName: (derivedColumn && derivedColumn.replace('__derived_from__', '')) || undefined,
            };
        });

        const allParentColumns = columnsWithParentName.map(col => col.parentName).filter(s => !!s);

        const derivedColumnGroup: GroupConfig = {
            name: 'Group by derived columns',
            isBuiltin: true,
            columns: columnsWithParentName.map(col => {
                if (!col.parentName && allParentColumns.indexOf(col.headerName) !== -1) {
                    col.parentName = col.headerName;
                }
                return { ...col };
            }),
        };

        fetch(`${apiUrl}/get-ag-grid-config?type=group`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
            .then(result => result.json())
            .then((data: { groups: GroupConfig[]; general_config: GeneralGroupConfig }) => {
                if (!data) {
                    return;
                }

                const loadedGroups = (data['groups'] || []).map(group => ({
                    ...group,
                    columns: columns.map(col => ({
                        ...col,
                        ...pick(group.columns.find(cc => cc.headerName === col.headerName) || {}, 'parentName'),
                    })),
                }));

                const allGroups = [derivedColumnGroup, ...loadedGroups];
                setGroups(allGroups);
                setConfig(prev_config => data['general_config'] || prev_config);

                if (data['general_config'] && data['general_config'].activeGroup) {
                    const activeGroup: GroupConfig | undefined = allGroups.find(
                        (gr: GroupConfig) => gr.name === data['general_config'].activeGroup
                    );

                    if (activeGroup) {
                        activateGroup(activeGroup, onColumnsChanged, gridColumnApi);
                    }
                }
            })
            .catch(err => console.error(err));
    }, [apiUrl, columns, onColumnsChanged, gridColumnApi]);

    React.useEffect(() => {
        if (containerEl && containerEl.current) {
            containerEl.current.parentElement?.parentElement?.setAttribute('style', 'width:500px');
            containerEl.current.parentElement?.setAttribute('style', 'width:100%');
        }
    }, []);

    return (
        <div ref={containerEl} className="ag-panel-container">
            <h4>Groups</h4>
            <AddGroupConfig
                groups={groups}
                onAddGroupConfig={name => {
                    const newGroup = { name, columns: columns.map(col => ({ ...col, parentName: 'Other' })) };

                    const changedConfig = { ...config, activeGroup: newGroup.name };
                    setConfig(changedConfig);
                    activateGroup(newGroup, onColumnsChanged, gridColumnApi);

                    setGroups([...groups, newGroup]);

                    saveGroupConfig(apiUrl, newGroup).catch(err =>
                        console.log('Error while saving group config:', err)
                    );
                    saveGeneralGroupConfig(apiUrl, changedConfig).catch(err =>
                        console.log('Error while saving general group config:', err)
                    );
                }}
            />

            <table style={{ marginTop: '10px' }}>
                <tbody>
                    {groups.map(group => (
                        <tr key={group.name}>
                            <td
                                style={{ fontWeight: group.name === config.activeGroup ? 700 : 500, cursor: 'pointer' }}
                                onClick={() => onActivateGroup(group)}
                            >
                                {group.name}
                            </td>
                            <td>
                                {group.name === config.activeGroup ? (
                                    <>
                                        <span className="material-icons btn-icon" onClick={() => onDeactivateGroup()}>
                                            visibility_off
                                        </span>
                                        {!group.isBuiltin ? (
                                            <span className="material-icons btn-icon btn-icon-disabled">delete</span>
                                        ) : null}
                                    </>
                                ) : (
                                    <>
                                        <span
                                            className="material-icons btn-icon"
                                            onClick={() => onActivateGroup(group)}
                                        >
                                            visibility
                                        </span>
                                        {!group.isBuiltin ? (
                                            <span
                                                onClick={() => onDeleteGroup(group)}
                                                className="material-icons btn-icon"
                                            >
                                                delete
                                            </span>
                                        ) : null}
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {groups.map((group, idx) =>
                group.name === config.activeGroup && !group.isBuiltin ? (
                    <ManageGroupConfig
                        key={group.name}
                        group={group}
                        onGroupChanged={changedGroup => handleGroupConfigChange(changedGroup, idx)}
                    />
                ) : null
            )}
        </div>
    );
}

export default GroupPanel;
