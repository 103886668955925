import React from 'react';
import { IHeaderParams } from 'ag-grid-community';

interface HeaderParams extends IHeaderParams {
    doingDuplicationCheck: boolean;
    changeDuplicationStatus: (colId: string, duplicationStatus: string) => void;
}

function CustomHeader(props: HeaderParams) {
    if (props.displayName === 'Columns' || !props.doingDuplicationCheck) {
        return props.displayName;
    }

    return (
        <div>
            {props.displayName}
            <br />
            <button onClick={() => props.changeDuplicationStatus(props.column.getColId(), 'not-duplicated')}>
                not-duplicated
            </button>
            <br />
            <button onClick={() => props.changeDuplicationStatus(props.column.getColId(), 'master')}>master</button>
            <br />
            <button onClick={() => props.changeDuplicationStatus(props.column.getColId(), 'merged')}>merged</button>
        </div>
    );
}
export default CustomHeader;
