import React from 'react';

import RotatedGrid from './RotatedGrid';

type Props = {
    origColumns: any[];
    apiUrl: string;
    duplicationCheckData: {
        checkingNow: boolean;
        keyColumn: string;
        keyValue: string;
    };
    rows: any[];
    onRowsChanged: (updatedRows: any[]) => void;
};

class DetailView extends React.Component<Props, {}> {
    async saveRowChange(row: any, updated: any) {
        const { apiUrl } = this.props;
        const old = { ...row };
        const response = await fetch(apiUrl, {
            method: 'PUT',
            body: JSON.stringify({ old, updated }),
            headers: { 'Content-Type': 'application/json' },
        });
        if (!response.ok) {
            throw new Error(`Error ${response.status}: ${response.statusText}`);
        }

        const resJson = await response.json();
        if (resJson.error) {
            throw new Error(resJson.error);
        }

        return { ...old, ...updated };
    }

    async changeRowDuplicationData(row: any, duplicationStatus: string) {
        const { duplicationCheckData } = this.props;
        const updated = {
            '*Duplication-Status': duplicationStatus,
            '*Duplication-Key-Column': duplicationCheckData.keyColumn,
            '*Duplication-Key-Value': duplicationCheckData.keyValue,
        };
        return this.saveRowChange(row, updated);
    }

    async markAllAs(duplicationStatus: string) {
        const { rows, onRowsChanged } = this.props;
        if (['to-merge', 'not-duplicated', 'not-checked'].indexOf(duplicationStatus) === -1) {
            return;
        }
        Promise.all(
            rows.map(async row => {
                try {
                    return await this.changeRowDuplicationData(row, duplicationStatus);
                } catch (err) {
                    console.log('Error while updating value: ', err);
                    return { ...row };
                }
            })
        ).then(updatedRows => {
            onRowsChanged(updatedRows);
        });
    }

    onDuplicationStatusChanged = async (detailViewRowIndex: number, duplicationStatus: string) => {
        const { rows, onRowsChanged } = this.props;
        if (['master', 'merged', 'not-duplicated'].indexOf(duplicationStatus) === -1) {
            return;
        }
        if (rows[detailViewRowIndex] === undefined) {
            return;
        }

        try {
            const updatedRows = await Promise.all(
                rows.map((row, idx) => {
                    if (detailViewRowIndex === idx) {
                        return this.changeRowDuplicationData(row, duplicationStatus);
                    } else if (duplicationStatus === 'master' && row['*Duplication-Status'] === 'master') {
                        return this.changeRowDuplicationData(row, 'to-merge');
                    }
                    return row;
                })
            );
            onRowsChanged(updatedRows);
        } catch (err) {
            console.log('Error while updating value: ', err);
        }
    };

    onRotatedGridCellDoubleClicked = ({
        rowIndex,
        column,
        value,
    }: {
        rowIndex: number;
        column: string;
        value: any;
    }) => {
        const { rows, duplicationCheckData, onRowsChanged } = this.props;
        if (!duplicationCheckData.checkingNow || rows[rowIndex] === undefined) {
            return;
        }
        if (rows[rowIndex][column] === undefined || rows[rowIndex][column] !== value) {
            return;
        }
        Promise.all(
            rows.map(async row => {
                try {
                    if (row['*Duplication-Status'] === 'master') {
                        return await this.saveRowChange(row, { [column]: value });
                    }
                    return { ...row };
                } catch (err) {
                    console.log('Error while updating value: ', err);
                    return { ...row };
                }
            })
        ).then(updatedRows => {
            onRowsChanged(updatedRows);
        });
    };

    render() {
        const { origColumns, rows, duplicationCheckData } = this.props;

        return (
            <div>
                <div style={{ marginBottom: '5px' }}>
                    {duplicationCheckData.checkingNow ? (
                        <>
                            <button onClick={() => this.markAllAs('not-duplicated')}>
                                mark all as "not-duplicated"
                            </button>{' '}
                            <button onClick={() => this.markAllAs('to-merge')}>mark all as "to-merge"</button>{' '}
                            <button onClick={() => this.markAllAs('not-checked')}>mark all as "not-checked"</button>
                        </>
                    ) : null}
                </div>
                <RotatedGrid
                    columns={origColumns}
                    data={rows}
                    doingDuplicationCheck={duplicationCheckData.checkingNow}
                    onDuplicationStatusChanged={this.onDuplicationStatusChanged}
                    onCellDoubleClicked={this.onRotatedGridCellDoubleClicked}
                />
            </div>
        );
    }
}

export default DetailView;
