// taken from https://gist.github.com/Fluidbyte/2973986
const CURRENCY_CODES: any = {
    USD: '$',
    CAD: 'CAD',
    EUR: '€',
    AED: 'AED',
    AFN: 'Af',
    ALL: 'ALL',
    AMD: 'AMD',
    ARS: 'ARS',
    AUD: 'AUD',
    AZN: 'man.',
    BAM: 'KM',
    BDT: 'Tk',
    BGN: 'BGN',
    BHD: 'BD',
    BIF: 'FBu',
    BND: 'BND',
    BOB: 'Bs',
    BRL: 'BRL',
    BWP: 'BWP',
    BYN: 'Br',
    BZD: 'BZD',
    CDF: 'CDF',
    CHF: 'CHF',
    CLP: 'CLP',
    CNY: 'CNY',
    COP: 'COP',
    CRC: '₡',
    CVE: 'CVE',
    CZK: 'Kč',
    DJF: 'Fdj',
    DKK: 'Dkr',
    DOP: 'DOP',
    DZD: 'DA',
    EEK: 'Ekr',
    EGP: 'EGP',
    ERN: 'Nfk',
    ETB: 'Br',
    GBP: '£',
    GEL: 'GEL',
    GHS: 'GHS',
    GNF: 'FG',
    GTQ: 'GTQ',
    HKD: 'HKD',
    HNL: 'HNL',
    HRK: 'kn',
    HUF: 'Ft',
    IDR: 'Rp',
    ILS: '₪',
    INR: 'Rs',
    IQD: 'IQD',
    IRR: 'IRR',
    ISK: 'Ikr',
    JMD: 'JMD',
    JOD: 'JD',
    JPY: '¥',
    KES: 'Ksh',
    KHR: 'KHR',
    KMF: 'CF',
    KRW: '₩',
    KWD: 'KD',
    KZT: 'KZT',
    LBP: 'LBP',
    LKR: 'SLRs',
    LTL: 'Lt',
    LVL: 'Ls',
    LYD: 'LD',
    MAD: 'MAD',
    MDL: 'MDL',
    MGA: 'MGA',
    MKD: 'MKD',
    MMK: 'MMK',
    MNT: '₮',
    MOP: 'MOP',
    MUR: 'MURs',
    MXN: 'MXN',
    MYR: 'RM',
    MZN: 'MTn',
    NAD: 'NAD',
    NGN: '₦',
    NIO: 'NIO',
    NOK: 'Nkr',
    NPR: 'NPRs',
    NZD: 'NZD',
    OMR: 'OMR',
    PAB: 'B/.',
    PEN: 'S/.',
    PHP: '₱',
    PKR: 'PKRs',
    PLN: 'zł',
    PYG: '₲',
    QAR: 'QR',
    RON: 'RON',
    RSD: 'din.',
    RUB: 'RUB',
    RWF: 'RWF',
    SAR: 'SR',
    SDG: 'SDG',
    SEK: 'Skr',
    SGD: 'SGD',
    SOS: 'Ssh',
    SYP: 'SYP',
    THB: '฿',
    TND: 'DT',
    TOP: 'TOP',
    TRY: 'TL',
    TTD: 'TTD',
    TWD: 'TWD',
    TZS: 'TSh',
    UAH: '₴',
    UGX: 'USh',
    UYU: 'UYU',
    UZS: 'UZS',
    VEF: 'Bs.F.',
    VND: '₫',
    XAF: 'FCFA',
    XOF: 'CFA',
    YER: 'YR',
    ZAR: 'R',
    ZMK: 'ZK',
    ZWL: 'ZWL',
};

function convertCurrencyCodeToSymbol(code: any) {
    return CURRENCY_CODES[code] || code;
}

function formatThousands(value: any) {
    return value && value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export { convertCurrencyCodeToSymbol, formatThousands };
