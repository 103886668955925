import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridReadyEvent, GridApi, CellDoubleClickedEvent } from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';

import { ColorSchemaType } from '../../lib/types';
import ColorSchemaPanel from './ColorSchemaPanel';
import CustomHeader from './CustomHeader';

function HSVtoRGB(h: number, s: number, v: number) {
    let r = 0,
        g = 0,
        b = 0,
        i,
        f,
        p,
        q,
        t;
    i = Math.floor(h * 6);
    f = h * 6 - i;
    p = v * (1 - s);
    q = v * (1 - f * s);
    t = v * (1 - (1 - f) * s);
    switch (i % 6) {
        case 0:
            r = v;
            g = t;
            b = p;
            break;
        case 1:
            r = q;
            g = v;
            b = p;
            break;
        case 2:
            r = p;
            g = v;
            b = t;
            break;
        case 3:
            r = p;
            g = q;
            b = v;
            break;
        case 4:
            r = t;
            g = p;
            b = v;
            break;
        case 5:
            r = v;
            g = p;
            b = q;
            break;
    }

    return {
        r: Math.round(r * 255),
        g: Math.round(g * 255),
        b: Math.round(b * 255),
    };
}

function generateHeatmapColor(heat: number, inverse: boolean) {
    if (inverse) {
        heat = 1 - heat;
    }
    const r = 255;
    const g = (1 - heat / 2) * 255;
    const b = (1 - heat / 2) * 255;
    return { r, g, b };
}

function generateColumnColor({ position, totalCount }: { position: number; totalCount: number }) {
    const h = (1 / totalCount) * position;
    const s = 1;
    const v = 1;
    return { h, s, v };
}

function isDarkColor({ r, g, b }: { r: number; g: number; b: number }): boolean {
    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    const hsp = Math.sqrt(0.229 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
    return hsp <= 127.5;
}

type Props = {
    columns: Array<{ name: string; visible: boolean }>;
    data: any[];
    doingDuplicationCheck: boolean;
    onDuplicationStatusChanged: (rowIndex: number, duplicationStatus: string) => void;
    onCellDoubleClicked: (params: { rowIndex: number; column: string; value: any }) => void;
};
type State = {
    colorSchema: ColorSchemaType;
};

class RotatedGrid extends React.Component<Props, State> {
    heatValues: Map<string, Map<any, number>>;
    columnColors: Map<string, any>;
    gridApi: GridApi | null = null;

    constructor(props: Props) {
        super(props);
        this.heatValues = new Map();
        this.columnColors = new Map();
        this.state = {
            colorSchema: (window.localStorage.getItem('RotatedGrid:colorSchema') as ColorSchemaType) || 'column',
        };
    }

    cellStyle = (params: any) => {
        const { colorSchema } = this.state;
        if (colorSchema === 'none') {
            return { backgroundColor: 'white', color: 'black' };
        }

        if (colorSchema === 'column' || colorSchema === 'heatmap+column' || colorSchema === 'inverseHeatmap+column') {
            const colNumber = Number(params.colDef.field.replace('row-', ''));
            for (let i = 1; i <= colNumber; i++) {
                if (params.value === params.data[`row-${i}`]) {
                    const val = this.columnColors.get(`row-${i}`);
                    if (!val) {
                        return { backgroundColor: 'white', color: 'black' };
                    }
                    const { h, s, v } = val;
                    const heat = this.heatValues.get(params.data.columns)?.get(params.value) || 0;
                    let ss = s;
                    if (colorSchema === 'heatmap+column') {
                        ss = heat;
                    } else if (colorSchema === 'inverseHeatmap+column') {
                        ss = 1 - heat;
                    }
                    const { r, g, b } = HSVtoRGB(h, ss, v);
                    return {
                        backgroundColor: `rgb(${r}, ${g}, ${b})`,
                        color: isDarkColor({ r, g, b }) ? 'white' : 'black',
                    };
                }
            }
        } else if (colorSchema === 'heatmap' || colorSchema === 'inverseHeatmap') {
            const values = this.heatValues.get(params.data.columns);
            if (values) {
                const { r, g, b } = generateHeatmapColor(
                    values.get(params.value) || 0,
                    colorSchema === 'inverseHeatmap'
                );
                return {
                    backgroundColor: `rgb(${r}, ${g}, ${b})`,
                    color: isDarkColor({ r, g, b }) ? 'white' : 'black',
                };
            }
        }
        return { backgroundColor: 'white', color: 'black' };
    };

    onGridReady = (params: GridReadyEvent) => {
        this.gridApi = params.api;
    };

    onCellDoubleClicked = (event: CellDoubleClickedEvent) => {
        const rowIndex = Number(event.colDef.field?.replace('row-', '')) - 1;
        const column = event.data['columns'];
        if (!isNaN(rowIndex) && event.value !== column) {
            this.props.onCellDoubleClicked({ rowIndex, column, value: event.value });
        }
    };

    render() {
        const { columns, data, doingDuplicationCheck, onDuplicationStatusChanged } = this.props;
        const { colorSchema } = this.state;

        const columnDefs: ColDef[] = [{ field: 'columns', pinned: true }];
        this.columnColors = new Map();

        if (!columns || !data || columns.length === 0 || data.length === 0) {
            return null;
        }

        for (let i = 0; i < data.length; i++) {
            columnDefs.push({ field: `row-${i + 1}`, cellStyle: this.cellStyle });
            this.columnColors.set(
                `row-${i + 1}`,
                generateColumnColor({ position: i + 1, totalCount: Math.max(data.length, 18) })
            );
        }

        const rows = [];

        for (const col of columns
            .filter(c => c.visible)
            .sort((a, b) => {
                if (a.name === '*Duplication-Status') return -1;
                if (b.name === '*Duplication-Status') return 1;
                return 0;
            })) {
            const heatmapValues = new Map<any, number>();
            const row: any = { columns: col.name };

            for (let j = 0; j < data.length; j++) {
                row[`row-${j + 1}`] = data[j][col.name];
                heatmapValues.set(data[j][col.name], (heatmapValues.get(data[j][col.name]) || 0) + 1);
            }
            rows.push(row);

            for (const [key, value] of heatmapValues) {
                const heat = 1 - value / data.length;
                heatmapValues.set(key, heat);
            }
            this.heatValues.set(col.name, heatmapValues);
        }

        return (
            <div className="ag-theme-balham" style={{ width: '100%' }}>
                <AgGridReact
                    domLayout="autoHeight"
                    rowData={rows}
                    rowSelection="multiple"
                    groupSelectsChildren={true}
                    columnDefs={columnDefs}
                    defaultColDef={{
                        resizable: true,
                        headerComponentParams: {
                            doingDuplicationCheck,
                            changeDuplicationStatus(colId: string, duplicationStatus: string) {
                                // colId is "row-<number>"
                                const rowIndex = Number(colId.replace('row-', '')) - 1;
                                if (!isNaN(rowIndex)) {
                                    onDuplicationStatusChanged(rowIndex, duplicationStatus);
                                }
                            },
                        },
                    }}
                    headerHeight={80}
                    onGridReady={this.onGridReady}
                    sideBar={{
                        toolPanels: [
                            {
                                id: 'columns',
                                labelDefault: 'Columns',
                                labelKey: 'columns',
                                iconKey: 'columns',
                                toolPanel: 'agColumnsToolPanel',
                            },
                            {
                                id: 'colorSchema',
                                labelDefault: 'Color Schema',
                                labelKey: 'colorSchema',
                                iconKey: 'colorSchema',
                                toolPanel: 'colorSchemaToolPanel',
                            },
                        ],
                    }}
                    frameworkComponents={{
                        agColumnHeader: CustomHeader,
                        colorSchemaToolPanel: () => (
                            <ColorSchemaPanel
                                selectedColorSchema={colorSchema}
                                onSelectedColorSchemaChanged={newColorSchema => {
                                    this.setState({ colorSchema: newColorSchema }, () => {
                                        this.gridApi?.redrawRows();
                                        window.localStorage.setItem('RotatedGrid:colorSchema', newColorSchema);
                                    });
                                }}
                            />
                        ),
                    }}
                    onCellDoubleClicked={this.onCellDoubleClicked}
                />
            </div>
        );
    }
}

export default RotatedGrid;
