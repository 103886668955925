import React from 'react';
import { useParams } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import {
    ColGroupDef,
    ColumnApi,
    ColumnVisibleEvent,
    GetContextMenuItemsParams,
    GridApi,
    IServerSideDatasource,
    RowClickedEvent,
    RowNode,
    GridReadyEvent,
} from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';

import { ColDef } from '../lib/types';
import { getColumnDefs } from '../lib/getColumnDef';
import { useQuery } from '../lib/hooks';
import GroupPanel from '../components/groups/GroupPanel';
import LayoutPanel from '../components/layouts/LayoutPanel';
import BooleanFilter from '../components/filters/BooleanFilter';
import BooleanCellRenderer from '../components/cellRenderers/BooleanCellRenderer';
import SQLColumnsPanel from '../components/sql/SQLColumnsPanel';
import DetailView from '../components/detailView/DetailView';

function getContextMenuItems(params: GetContextMenuItemsParams) {
    return [
        {
            name: 'Expand All',
            action: () => params.api && params.api.expandAll(),
            tooltip: 'Expands all Columns',
        },
        {
            name: 'Collapse All',
            action: () => params.api && params.api.collapseAll(),
            tooltip: 'Expands all Columns',
        },
        'separator',
        ...(params.defaultItems || []),
        'separator',
        'resetColumns',
    ];
}

function getApiUrl() {
    return new URLSearchParams(window.location.search).get('api')!;
}

function getLoadDataParams(node: RowNode, api: GridApi, columnApi: ColumnApi) {
    const params: any = {
        startRow: 0,
        endRow: node.data['__childCount'],
        valueCols: [],
        pivotCols: [],
        pivotMode: false,
        filterModel: api.getFilterModel(),
        sortModel: [],
        rowGroupCols: [],
        groupKeys: [],
    };
    for (const colState of columnApi.getColumnState()) {
        if (colState.sort) {
            params.sortModel.splice(colState.sortIndex || 0, 0, { colId: colState.colId, sort: colState.sort });
        }
    }
    let currNode = node;
    while (currNode.level > -1) {
        const field = currNode.field;
        params.groupKeys.unshift(currNode.key);
        params.rowGroupCols.unshift({ id: field, aggFunc: null, displayName: field, field: field });
        if (currNode.parent) {
            currNode = currNode.parent;
        } else {
            break;
        }
    }

    return params;
}

function createServerSideDatasource(fullTableApiUrl: string): IServerSideDatasource {
    return {
        getRows: function(params) {
            // console.log(JSON.stringify(params.request));

            fetch(fullTableApiUrl, {
                method: 'post',
                body: JSON.stringify(params.request),
                headers: { 'Content-Type': 'application/json; charset=utf-8' },
            })
                .then(httpResponse => httpResponse.json())
                .then(data => {
                    params.successCallback(data.rows, data.lastRow);
                })
                .catch(error => {
                    console.error(error);
                    params.failCallback();
                });
        },
    };
}

function GridTable() {
    const [columnDefs, setColumnDefs] = React.useState<Array<ColDef | ColGroupDef>>([]);

    const [origColumns, setOrigColumns] = React.useState<any[]>([]);
    const [detailViewRows, setDetailViewRows] = React.useState<any[]>([]);
    const [showDetailedView, setShowDetailedView] = React.useState<boolean>(false);
    const [detailedViewWidth, setDetailedViewWidth] = React.useState<number>(
        Number(window.localStorage.getItem('detailedViewWidth')) || 80
    );
    const [showMainGrid, setShowMainGrid] = React.useState<boolean>(true);
    const [duplicationCheckGroupRowNode, setDuplicationCheckGroupRowNode] = React.useState<RowNode | null>(null);
    const [duplicationCheckData, setDuplicationCheckData] = React.useState({
        checkingNow: false,
        keyColumn: '',
        keyValue: '',
    });

    const apiUrl = getApiUrl();
    const { id, table_name } = useParams<{ id: string; table_name: string }>();
    const fullTableApiUrl = `${apiUrl}/table/${id}/${table_name}`;

    const query = useQuery();
    React.useEffect(() => {
        const title = query.get('title');
        if (title) {
            window.document.title = `${table_name} ${title}`;
        }
    }, [query, table_name]);

    React.useEffect(() => {
        fetch(`${fullTableApiUrl}/get-columns`, {
            method: 'get',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(httpResponse => httpResponse.json())
            .then(data => {
                setColumnDefs(getColumnDefs({ columns: data, apiUrl: fullTableApiUrl }));
                setOrigColumns(data.map((col: any) => ({ ...col, visible: true })));
            })
            .catch(error => {
                console.error(error);
            });
    }, [fullTableApiUrl]);

    const onGridReady = (event: GridReadyEvent) => {
        const datasource = createServerSideDatasource(fullTableApiUrl);
        event.api.setServerSideDatasource(datasource);
    };

    function onRowClicked(event: RowClickedEvent) {
        // @ts-ignore
        if (!showDetailedView && event.event?.altKey) {
            setShowDetailedView(true);
        }
        if (event.node.group) {
            if (event.node.field?.endsWith('-Duplicate-Group-Id')) {
                setDuplicationCheckGroupRowNode(event.node);
                setDuplicationCheckData({
                    checkingNow: true,
                    keyColumn: event.node.field,
                    keyValue: event.node.key || '',
                });
            } else {
                setDuplicationCheckData({ checkingNow: false, keyColumn: '', keyValue: '' });
                setDuplicationCheckGroupRowNode(null);
            }

            const params = getLoadDataParams(event.node, event.api, event.columnApi);
            fetch(fullTableApiUrl, {
                method: 'post',
                body: JSON.stringify(params),
                headers: { 'Content-Type': 'application/json; charset=utf-8' },
            })
                .then(httpResponse => httpResponse.json())
                .then(data => {
                    setDetailViewRows(data.rows);
                })
                .catch(error => {
                    console.error(error);
                });
        } else {
            setDuplicationCheckGroupRowNode(null);
            setDuplicationCheckData({ checkingNow: false, keyColumn: '', keyValue: '' });
            const selectedNodes = event.api.getSelectedNodes();
            const selectedData = selectedNodes.map(node => node.data);
            if (selectedData.length > 0) {
                setDetailViewRows(selectedData);
            }
        }
    }

    function onColumnVisibilityChanged(event: ColumnVisibleEvent) {
        const columns = event.columns || [];
        setOrigColumns(
            origColumns.map(col => {
                const changedCol = columns.find(c => c['colId'] === col.name);
                if (changedCol) {
                    return { ...col, visible: changedCol['visible'] };
                }
                return col;
            })
        );
    }

    if (columnDefs.length === 0) {
        return (
            <div className="ag-theme-balham" style={{ width: '100%', height: '100vh' }}>
                loading...
            </div>
        );
    }

    return (
        <div>
            {showDetailedView ? (
                <div style={{ width: showMainGrid ? `${detailedViewWidth}%` : '100%', float: 'left' }}>
                    <div style={{ margin: '5px', textAlign: 'right' }}>
                        <button
                            onClick={() => {
                                setShowDetailedView(false);
                                setShowMainGrid(true);
                            }}
                        >
                            close detail view
                        </button>{' '}
                        <button onClick={() => setShowMainGrid(!showMainGrid)}>
                            {showMainGrid ? 'hide main grid' : 'show main grid'}
                        </button>{' '}
                        <button
                            disabled={detailedViewWidth < 20}
                            onClick={() => {
                                const newWidth = detailedViewWidth - 10;
                                setDetailedViewWidth(newWidth);
                                window.localStorage.setItem('detailedViewWidth', newWidth.toString());
                            }}
                        >
                            &lt;&lt;
                        </button>{' '}
                        <button
                            disabled={detailedViewWidth > 80}
                            onClick={() => {
                                const newWidth = detailedViewWidth + 10;
                                setDetailedViewWidth(newWidth);
                                window.localStorage.setItem('detailedViewWidth', newWidth.toString());
                            }}
                        >
                            &gt;&gt;
                        </button>
                    </div>
                    <DetailView
                        origColumns={origColumns}
                        apiUrl={fullTableApiUrl}
                        duplicationCheckData={duplicationCheckData}
                        rows={detailViewRows}
                        onRowsChanged={updatedRows => {
                            setDetailViewRows(updatedRows);
                            duplicationCheckGroupRowNode?.childStore?.refreshStore(true);
                        }}
                    />
                </div>
            ) : null}
            <div
                className="ag-theme-balham"
                style={{
                    width: showDetailedView ? `${100 - detailedViewWidth}%` : '100%',
                    height: '100vh',
                    float: 'right',
                    display: showMainGrid ? 'inherit' : 'none',
                }}
                data-cy="main-grid"
            >
                <AgGridReact
                    rowSelection="multiple"
                    groupSelectsChildren={true}
                    sideBar={{
                        toolPanels: [
                            {
                                id: 'columns',
                                labelDefault: 'Columns',
                                labelKey: 'columns',
                                iconKey: 'columns',
                                toolPanel: 'agColumnsToolPanel',
                            },
                            {
                                id: 'filters',
                                labelDefault: 'Filters',
                                labelKey: 'filters',
                                iconKey: 'filter',
                                toolPanel: 'agFiltersToolPanel',
                            },
                            {
                                id: 'groups',
                                labelDefault: 'Groups',
                                labelKey: 'groups',
                                iconKey: 'group',
                                toolPanel: 'groupToolPanel',
                            },
                            {
                                id: 'layouts',
                                labelDefault: 'Layouts',
                                labelKey: 'layouts',
                                iconKey: 'layouts',
                                toolPanel: 'layoutToolPanel',
                            },
                            {
                                id: 'sqlColumns',
                                labelDefault: 'SQLColumns',
                                labelKey: 'sqlColumns',
                                iconKey: 'sqlColumns',
                                toolPanel: 'sqlColumnsPanel',
                            },
                        ],
                    }}
                    frameworkComponents={{
                        groupToolPanel: (params: { columnApi: ColumnApi }) => (
                            <GroupPanel
                                columns={columnDefs as ColDef[]}
                                onColumnsChanged={columns => setColumnDefs(columns)}
                                apiUrl={fullTableApiUrl}
                                gridColumnApi={params.columnApi}
                            />
                        ),
                        layoutToolPanel: (params: { api: GridApi; columnApi: ColumnApi }) => (
                            <LayoutPanel
                                apiUrl={fullTableApiUrl}
                                gridApi={params.api}
                                gridColumnApi={params.columnApi}
                            />
                        ),
                        sqlColumnsPanel: (params: { api: GridApi; columnApi: ColumnApi }) => (
                            <SQLColumnsPanel apiUrl={fullTableApiUrl} gridApi={params.api} />
                        ),
                        booleanFilter: BooleanFilter,
                        booleanCellRenderer: BooleanCellRenderer,
                    }}
                    suppressDragLeaveHidesColumns={true}
                    rowGroupPanelShow="onlyWhenGrouping" //"always"
                    columnDefs={columnDefs}
                    defaultColDef={{ resizable: true, sortable: true, editable: true }}
                    serverSideStoreType="partial"
                    rowModelType="serverSide"
                    enableRangeSelection={true}
                    enableCharts={true}
                    getChildCount={(data: any) => {
                        if (!data) {
                            return undefined;
                        }
                        return data.__childCount;
                    }}
                    getContextMenuItems={getContextMenuItems}
                    onRowClicked={onRowClicked}
                    onColumnVisible={onColumnVisibilityChanged}
                    onGridReady={onGridReady}
                />
            </div>
        </div>
    );
}

export default GridTable;
