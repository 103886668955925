import React from 'react';
import { ColorSchemaType } from '../../lib/types';

function ColorSchemaPanel({
    selectedColorSchema,
    onSelectedColorSchemaChanged,
}: {
    selectedColorSchema: ColorSchemaType;
    onSelectedColorSchemaChanged: (newColorSchema: ColorSchemaType) => void;
}) {
    const [colorSchema, setColorSchema] = React.useState(selectedColorSchema);
    const schemaTypes = ['heatmap', 'inverseHeatmap', 'column', 'heatmap+column', 'inverseHeatmap+column', 'none'];

    function onColorSchemaChange(value: ColorSchemaType) {
        setColorSchema(value);
        onSelectedColorSchemaChanged(value);
    }
    return (
        <div>
            <h3>Select color schema</h3>
            {schemaTypes.map(t => (
                <div key={t}>
                    <input
                        type="radio"
                        id={`${t}_schema`}
                        name="color_schema"
                        value={t}
                        checked={colorSchema === t}
                        onChange={event => onColorSchemaChange(event.currentTarget.value as ColorSchemaType)}
                    />
                    <label htmlFor={`${t}_schema`}>{t}</label>
                </div>
            ))}
        </div>
    );
}

export default ColorSchemaPanel;
