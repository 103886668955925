import React from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '../lib/hooks';

function Dataset() {
    const query = useQuery();
    const apiUrl = query.get('api');
    const title = query.get('title');
    const { id } = useParams<{ id: string }>();
    const [tables, setTables] = React.useState<Array<{ name: string; row_count: number }>>([]);

    React.useEffect(() => {
        fetch(`${apiUrl}/dataset/${id}/get-tables`, {
            method: 'get',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(httpResponse => httpResponse.json())
            .then(data => {
                setTables(data);
            })
            .catch(error => {
                console.error(error);
            });
    }, [id, apiUrl]);
    React.useEffect(() => {
        if (title) {
            window.document.title = title;
        }
    }, [title]);

    return (
        <div style={{ width: '1000px', margin: 'auto', marginTop: '20px' }}>
            <h3 data-cy="title">{query.get('title')}</h3>
            <ul>
                {tables.map(table => (
                    <li key={table.name}>
                        <a
                            href={`/grid-table/${id}/${table.name}?api=${apiUrl}&mode=paginate&title=${title}`}
                            target="_blank"
                            rel="noreferrer"
                            data-cy="grid-table-link"
                        >
                            {table.name}
                        </a>{' '}
                        ({table.row_count} rows)
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Dataset;
