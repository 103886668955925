import React from 'react';

function toMB(size: number) {
    let str = (size / (1024 * 1024)).toFixed(2);
    // show max 2 digits after the `.` but keep all digits for big numbers
    // ALWAYS use MB -- I find it totally frustrating when kB MB and GB are mixed
    while (str.indexOf('.') >= 0 && str.length > 4) {
        str = str.slice(0, -1);
    }
    return `${str} MB`;
}

function HistoryDirectory({
    dir,
    fetchApi,
    sharedHistory,
}: {
    dir: any;
    sharedHistory: boolean;
    fetchApi(params: { path: string; method: string; data: any }): void;
}) {
    function onDeleteAllDatasetHistory(dir: { db_dir: string; db_files: any[] }) {
        if (
            window.confirm(
                `Are you sure to delete whole ${sharedHistory ? 'shared ' : ''}dataset history? There are ${
                    dir.db_files.length
                } version(s)`
            )
        ) {
            fetchApi({ path: '/delete-dataset-all-history', method: 'DELETE', data: { history_folder: dir.db_dir } });
        }
    }

    function onDeleteOneHistory(params: { db_dir: string; db_file: string; created_at: string }) {
        const { db_dir, db_file, created_at } = params;
        if (window.confirm(`Are you sure to delete ${sharedHistory ? 'shared ' : ''}version "${created_at}"?`)) {
            fetchApi({ path: '/delete-history', method: 'DELETE', data: { history_folder: db_dir, db_file } });
        }
    }

    function onDeleteHistoryOlderThanDatetime(
        dir: { db_dir: string; db_files: any[] },
        timestamp: string,
        created_at: string
    ) {
        const count = dir.db_files.filter(f => f.timestamp <= timestamp).length;
        if (
            window.confirm(
                `Are you sure to delete  ${
                    sharedHistory ? 'shared ' : ''
                }versions older than "${created_at}"? There are ${count} version(s).`
            )
        ) {
            fetchApi({
                path: '/delete-history-older-than-datetime',
                method: 'DELETE',
                data: { history_folder: dir.db_dir, timestamp },
            });
        }
    }

    function onShareOneHistory(dataset_name: string, db_file: string) {
        fetchApi({ path: '/share', method: 'POST', data: { dataset_name, db_file } });
    }

    function onShareAllDatasetHistory(dataset_name: string) {
        fetchApi({ path: '/share-dataset-all-history', method: 'POST', data: { dataset_name } });
    }

    return (
        <details data-cy={`${sharedHistory ? 'shared-' : ''}dataset-detail`}>
            <summary data-cy="dataset-name">
                {dir.name} ({dir.db_files.length} versions,{' '}
                {toMB(dir.db_files.reduce((s: number, f: any) => s + f.file_size, 0))})
                <span
                    onClick={event => {
                        event.preventDefault();
                        onDeleteAllDatasetHistory(dir);
                    }}
                    className="material-icons btn-icon"
                    title="Delete all histories of this dataset"
                >
                    delete
                </span>{' '}
                {!sharedHistory ? (
                    <span
                        onClick={event => {
                            event.preventDefault();
                            onShareAllDatasetHistory(dir.name);
                        }}
                        className="material-icons btn-icon"
                        title="Share history"
                        data-cy="share-whole-dataset-history"
                    >
                        share
                    </span>
                ) : null}
            </summary>
            <ul>
                {dir.db_files.map((file: any) => (
                    <li key={file.timestamp}>
                        <a
                            href={`/dataset/${file.uid}?api=${window.location.origin}/api&mode=paginate&title=${file.title}`}
                            target="_blank"
                            rel="noreferrer"
                            data-cy="dataset-link"
                        >
                            {`${file.created_at} ${file.title.replace(/ \d\d:\d\d:\d\d /, ' ')}`}
                        </a>
                        {` (${toMB(file.file_size)})`}
                        <span
                            onClick={event => {
                                event.preventDefault();
                                onDeleteOneHistory({
                                    db_dir: dir.db_dir,
                                    db_file: file.db_file,
                                    created_at: file.timestamp,
                                });
                            }}
                            className="material-icons btn-icon"
                            title="Delete history"
                            data-cy="delete-one-history"
                        >
                            delete
                        </span>{' '}
                        <span
                            onClick={event => {
                                event.preventDefault();
                                onDeleteHistoryOlderThanDatetime(dir, file.timestamp, file.created_at);
                            }}
                            className="material-icons btn-icon"
                            title="Delete all histories older than this"
                            data-cy="delete-older-history"
                        >
                            file_download_off
                        </span>{' '}
                        {!sharedHistory ? (
                            <span
                                onClick={event => {
                                    event.preventDefault();
                                    onShareOneHistory(dir.name, file.db_file);
                                }}
                                className="material-icons btn-icon"
                                title="Share history"
                                data-cy="share-one-history"
                            >
                                share
                            </span>
                        ) : null}
                    </li>
                ))}
            </ul>
        </details>
    );
}

function Home() {
    const [histories, setHistories] = React.useState<Array<any>>([]);
    const [sharedHistories, setSharedHistories] = React.useState<{ [user_folder: string]: Array<any> }>({});

    function loadHistories() {
        fetch(`/api/history/list`)
            .then(httpResponse => httpResponse.json())
            .then(data => {
                console.log(data);
                setHistories(data.history_folders);
                setSharedHistories(data.shared_folders);
            })
            .catch(error => {
                console.error(error);
            });
    }

    function fetchApi({ path, data, method }: { path: string; data: any; method: string }) {
        fetch(`/api/history${path}`, {
            method,
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(httpResponse => httpResponse.json())
            .then(res => {
                console.log(res);
                return loadHistories();
            })
            .catch(error => {
                console.error(error);
            });
    }

    React.useEffect(() => {
        loadHistories();
    }, []);

    return (
        <div style={{ width: '1000px', margin: 'auto', marginTop: '20px' }}>
            <h3 data-cy="title">Histories</h3>
            {histories.map(dir => (
                <HistoryDirectory dir={dir} fetchApi={fetchApi} sharedHistory={false} key={dir.name} />
            ))}

            <h3 data-cy="shared-history-title">Shared histories</h3>
            {Object.keys(sharedHistories).map(user_folder => {
                const historyList = sharedHistories[user_folder];
                return (
                    <details key={user_folder} data-cy="user-shared-folder-detail" open={true}>
                        <summary>{user_folder}</summary>
                        <ul style={{ listStyle: 'none', paddingInlineStart: '20px' }}>
                            {historyList.map(dir => (
                                <li key={dir.name}>
                                    <HistoryDirectory dir={dir} fetchApi={fetchApi} sharedHistory={true} />
                                </li>
                            ))}
                        </ul>
                    </details>
                );
            })}
        </div>
    );
}

export default Home;
