import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import Dataset from './pages/Dataset';
import GridTable from './pages/GridTable';

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/" exact={true} component={Home} />
                <Route path="/dataset/:id" exact={true} component={Dataset} />
                <Route path="/grid-table/:id/:table_name" exact={true} component={GridTable} />
            </Switch>
        </Router>
    );
}

export default App;
