import React from 'react';
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';

class BooleanFilter extends React.Component<IFilterParams, { selectedValues: Array<boolean | null> }> {
    constructor(props: IFilterParams) {
        super(props);

        this.state = {
            selectedValues: [true, false, null],
        };

        this.onChange = this.onChange.bind(this);
    }

    isFilterActive() {
        const { selectedValues } = this.state;

        return [true, false, null].some(vv => !selectedValues.includes(vv));
    }

    doesFilterPass(params: IDoesFilterPassParams) {
        return this.state.selectedValues.includes(this.props.valueGetter(params.node));
    }

    getModel() {
        const isFilterActive = this.isFilterActive();
        return (isFilterActive && { values: this.state.selectedValues, filterType: 'boolean' }) || null;
    }

    setModel(model: { values: Array<boolean | null> }) {
        this.setState({ selectedValues: (model && model.values) || [true, false, null] });
    }

    onChange(checked: boolean, value: 'all' | boolean | null) {
        const { selectedValues } = this.state;
        let newValues = [...selectedValues];

        if (checked) {
            if (value === 'all') {
                newValues = [true, false, null];
            } else if (!newValues.includes(value)) {
                newValues.push(value);
            }
        } else {
            if (value === 'all') {
                newValues = [];
            } else if (newValues.includes(value)) {
                newValues = newValues.filter(vv => vv !== value);
            }
        }

        if (newValues.length !== selectedValues.length || newValues.some(vv => !selectedValues.includes(vv))) {
            this.setState(
                {
                    selectedValues: newValues,
                },
                () => {
                    this.props.filterChangedCallback();
                }
            );
        }
    }

    render() {
        const { selectedValues } = this.state;

        return (
            <div style={{ height: '80px', margin: '10px' }}>
                <div>
                    <label>
                        <input
                            type="checkbox"
                            onChange={event => this.onChange(event.target.checked, 'all')}
                            checked={[true, false, null].every(vv => selectedValues.includes(vv))}
                        />
                        Select all
                    </label>
                </div>
                <div>
                    <label>
                        <input
                            type="checkbox"
                            onChange={event => this.onChange(event.target.checked, true)}
                            checked={selectedValues.includes(true)}
                        />
                        True
                    </label>
                </div>
                <div>
                    <label>
                        <input
                            type="checkbox"
                            onChange={event => this.onChange(event.target.checked, false)}
                            checked={selectedValues.includes(false)}
                        />
                        False
                    </label>
                </div>
                <div>
                    <label>
                        <input
                            type="checkbox"
                            onChange={event => this.onChange(event.target.checked, null)}
                            checked={selectedValues.includes(null)}
                        />
                        No data
                    </label>
                </div>
            </div>
        );
    }
}
export default BooleanFilter;
