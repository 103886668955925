import React from 'react';
import { ColumnApi, GridApi, ColumnState } from 'ag-grid-community';

import '../../css/ag-grid-panel.css';
import { Layout } from '../../lib/types';
import AddLayout from './AddLayout';

type GeneralLayoutConfig = {
    activeLayout: string;
};

const EVENTS_RELEVANT_FOR_LAYOUT = [
    'sortChanged',
    'filterChanged',
    'columnPinned',
    'columnVisible',
    'columnResized',
    'columnMoved',
    'columnPivotChanged',
    'columnPivotModeChanged',
    'columnRowGroupChanged',
];

async function saveLayoutConfig(apiUrl: string, changedLayout: Layout, isDeleted = false) {
    const response = await fetch(`${apiUrl}/save-ag-grid-config`, {
        method: 'POST',
        body: JSON.stringify({ type: 'layout', layout: changedLayout, isDeleted }),
        headers: { 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
        console.log('Error while saving layout config: ');
        console.log(`Error ${response.status}: ${response.statusText}`);
        return;
    }
}

async function saveGeneralLayoutConfig(apiUrl: string, changedConfig: GeneralLayoutConfig) {
    const response = await fetch(`${apiUrl}/save-ag-grid-config`, {
        method: 'POST',
        body: JSON.stringify({ type: 'layout_general_config', general_config: changedConfig }),
        headers: { 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
        console.log('Error while saving general layout config: ');
        console.log(`Error ${response.status}: ${response.statusText}`);
        return;
    }
}

function getCurrentLayout(name: string, gridApi: GridApi, gridColumnApi: ColumnApi): Layout {
    const columns = gridColumnApi.getColumnState().map<ColumnState>(c => {
        const { aggFunc, colId, hide, pinned, pivotIndex, rowGroupIndex, width, sort, sortIndex } = c;
        return {
            aggFunc: typeof aggFunc === 'function' ? undefined : aggFunc,
            colId,
            hide,
            pinned,
            pivotIndex,
            rowGroupIndex,
            width,
            sort,
            sortIndex,
        };
    });

    return { name, filterModel: gridApi.getFilterModel(), columns };
}

function activateLayout(layout: Layout, gridApi: GridApi, gridColumnApi: ColumnApi, isChangingLayout: any) {
    isChangingLayout.current = true;

    gridApi.setFilterModel(layout.filterModel || {});
    gridColumnApi.applyColumnState({ state: layout.columns || [] });

    setTimeout(() => {
        gridApi.onFilterChanged();
        setTimeout(() => {
            isChangingLayout.current = false;
        }, 0);
    }, 0);
}

function LayoutPanel({
    apiUrl,
    gridApi,
    gridColumnApi,
}: {
    apiUrl: string;
    gridApi: GridApi;
    gridColumnApi: ColumnApi;
}) {
    const [layouts, setLayouts] = React.useState<Layout[]>([]);
    const [isActiveLayoutChanged, setActiveLayoutChanged] = React.useState<boolean>(false);

    const [config, setConfig] = React.useState<GeneralLayoutConfig>({ activeLayout: '' });
    const isChangingLayout = React.useRef(false);
    const initialLayout = React.useRef<Layout | null>(null);

    function addNewLayout(newLayout: Layout) {
        const changedConfig = { ...config, activeLayout: newLayout.name };
        setConfig(changedConfig);
        activateLayout(newLayout, gridApi, gridColumnApi, isChangingLayout);
        setActiveLayoutChanged(false);

        setLayouts([...layouts, newLayout]);

        saveLayoutConfig(apiUrl, newLayout).catch(err => console.log('Error while saving layout config:', err));
        saveGeneralLayoutConfig(apiUrl, changedConfig).catch(err =>
            console.log('Error while saving general layout config:', err)
        );
    }

    function renameLayout(layout: Layout) {
        const newName = window.prompt('Enter new name', layout.name) || 'newName';
        const layoutNames = layouts.filter(ll => ll.name !== layout.name).map(gr => gr.name);

        if (layoutNames.includes(newName)) {
            alert('Name duplicated!');
            return;
        }

        if (newName === layout.name) {
            return;
        }

        const modifiedLayout = { ...layout, name: newName };
        setLayouts(layouts.map(ll => (ll.name !== layout.name ? ll : { ...layout, name: newName })));
        saveLayoutConfig(apiUrl, modifiedLayout).catch(err => console.log('Error while saving layout config:', err));

        saveLayoutConfig(apiUrl, layout, true).catch(err => console.log('Error while saving layout config:', err));
    }

    function onActivateLayout(layout: Layout) {
        const changedConfig = { ...config, activeLayout: layout.name };
        setConfig(changedConfig);
        activateLayout(layout, gridApi, gridColumnApi, isChangingLayout);
        setActiveLayoutChanged(false);
        saveGeneralLayoutConfig(apiUrl, changedConfig).catch(err =>
            console.log('Error while saving general layout config:', err)
        );
    }

    function onDeactivateLayout() {
        const changedConfig = { ...config, activeLayout: '' };
        setConfig(changedConfig);
        saveGeneralLayoutConfig(apiUrl, changedConfig).catch(err =>
            console.log('Error while saving general layout config:', err)
        );

        if (initialLayout.current) {
            activateLayout(initialLayout.current as Layout, gridApi, gridColumnApi, isChangingLayout);
            setActiveLayoutChanged(false);
        }
    }

    function onSaveLayout(layout: Layout) {
        console.log(`### saving layout "${layout.name}"`);
        const changedLayout = getCurrentLayout(layout.name, gridApi, gridColumnApi);
        setLayouts(layouts.map(ll => ({ ...(ll.name === layout.name ? changedLayout : ll) })));
        saveLayoutConfig(apiUrl, changedLayout)
            .then(() => {
                setActiveLayoutChanged(false);
            })
            .catch(err => {
                window.alert(`Error: ${err}`);
                console.log('Error while saving layout config:', err);
            });
    }

    function onDeleteLayout(layout: Layout) {
        if (!window.confirm('Are you sure?')) {
            return;
        }

        const deletedLayout = layouts.find(ll => ll.name === layout.name);
        if (deletedLayout) {
            saveLayoutConfig(apiUrl, deletedLayout, true).catch(err =>
                console.log('Error while saving layout config:', err)
            );
        }

        setLayouts(layouts.filter(ll => ll.name !== layout.name));
    }

    function onDuplicateLayout(layout: Layout) {
        const layoutNames = layouts.map(ll => ll.name);

        let newName = `${layout.name} copy`;
        let c = 1;
        while (layoutNames.includes(newName)) {
            c += 1;
            newName = `${layout.name} copy ${c}`;
        }

        const newLayout = { ...layout, name: newName };
        addNewLayout(newLayout);
    }

    React.useEffect(() => {
        initialLayout.current = getCurrentLayout('initial', gridApi, gridColumnApi);
    }, [gridApi, gridColumnApi]);

    React.useEffect(() => {
        function handleLayoutChange(event: string) {
            if (isActiveLayoutChanged || !EVENTS_RELEVANT_FOR_LAYOUT.includes(event) || isChangingLayout.current) {
                return;
            }

            setActiveLayoutChanged(true);
        }

        gridApi.addGlobalListener(handleLayoutChange);
        return () => {
            gridApi.removeGlobalListener(handleLayoutChange);
        };
    });

    React.useEffect(() => {
        fetch(`${apiUrl}/get-ag-grid-config?type=layout`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
            .then(result => result.json())
            .then((data: { layouts: Layout[]; general_config: GeneralLayoutConfig }) => {
                if (!data) {
                    return;
                }

                const loadedLayouts = (data['layouts'] || []).map(ll => {
                    if (!initialLayout.current) {
                        return ll;
                    }

                    const columns = ll.columns || [];
                    return {
                        ...ll,
                        columns: initialLayout.current.columns.map(col => ({
                            ...col,
                            ...(columns.find(cc => cc.colId === col.colId) || {}),
                        })),
                    };
                });

                setLayouts(loadedLayouts);

                setConfig(prev_config => data['general_config'] || prev_config);

                if (data['general_config'] && data['general_config'].activeLayout) {
                    const activeLayout: Layout | undefined = loadedLayouts.find(
                        gr => gr.name === data['general_config'].activeLayout
                    );

                    if (activeLayout) {
                        activateLayout(activeLayout, gridApi, gridColumnApi, isChangingLayout);
                        setActiveLayoutChanged(false);
                    }
                }
            })
            .catch(err => console.error(err));
    }, [apiUrl, gridApi, gridColumnApi]);

    return (
        <div className="ag-panel-container">
            <h4>Layouts</h4>
            <AddLayout
                layouts={layouts}
                onAddLayout={name => {
                    const newLayout = getCurrentLayout(name, gridApi, gridColumnApi);
                    addNewLayout(newLayout);
                }}
            />

            <table style={{ marginTop: '10px' }}>
                <tbody>
                    {layouts.map(layout => (
                        <tr key={layout.name}>
                            <td
                                style={{
                                    fontWeight: layout.name === config.activeLayout ? 700 : 500,
                                    cursor: 'pointer',
                                }}
                                onClick={() => onActivateLayout(layout)}
                            >
                                {layout.name}
                            </td>
                            <td>
                                {layout.name === config.activeLayout ? (
                                    <span
                                        title="Deactivate"
                                        className="material-icons btn-icon"
                                        onClick={() => onDeactivateLayout()}
                                    >
                                        visibility_off
                                    </span>
                                ) : (
                                    <span
                                        title="Activate"
                                        className="material-icons btn-icon"
                                        onClick={() => onActivateLayout(layout)}
                                    >
                                        visibility
                                    </span>
                                )}

                                <span
                                    title="Rename"
                                    onClick={() => renameLayout(layout)}
                                    className="material-icons btn-icon"
                                >
                                    edit
                                </span>

                                <span
                                    style={{ fontSize: '17px' }}
                                    onClick={() => onDuplicateLayout(layout)}
                                    title="Duplicate"
                                    className="material-icons btn-icon"
                                >
                                    file_copy
                                </span>
                                {layout.name === config.activeLayout && isActiveLayoutChanged ? (
                                    <span
                                        onClick={() => onSaveLayout(layout)}
                                        title="Save"
                                        className="material-icons btn-icon"
                                    >
                                        save
                                    </span>
                                ) : (
                                    <span className="material-icons btn-icon btn-icon-disabled">save</span>
                                )}

                                {layout.name !== config.activeLayout ? (
                                    <span
                                        title="Remove"
                                        onClick={() => onDeleteLayout(layout)}
                                        className="material-icons btn-icon"
                                    >
                                        delete
                                    </span>
                                ) : (
                                    <span className="material-icons btn-icon btn-icon-disabled">delete</span>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default LayoutPanel;
