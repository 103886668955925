import { ColDef } from './types';
import { ValueSetterParams } from 'ag-grid-community';

import { convertCurrencyCodeToSymbol, formatThousands } from './currency';

const noDataFilterOptions = [
    {
        displayKey: 'noData', // ag-grid uses 'empty' key for other filter.
        displayName: 'no Data',
        test: function(filterValue: any, cellValue: any) {
            return cellValue === undefined;
        },
        hideFilterInput: true,
    },
    {
        displayKey: 'hasData',
        displayName: 'has Data',
        test: function(filterValue: any, cellValue: any) {
            return cellValue !== undefined;
        },
        hideFilterInput: true,
    },
];

const textFilterOptions = [
    'empty',
    'equals',
    'notEqual',
    'contains',
    'notContains',
    'startsWith',
    'endsWith',
    {
        displayKey: 'regex',
        displayName: 'Regex',
        test: function(filterValue: any, cellValue: any) {
            const rx = new RegExp(filterValue.toString());
            return rx.test(cellValue.toString());
        },
    },
    {
        displayKey: 'regexi',
        displayName: 'Regex ignore case',
        test: function(filterValue: any, cellValue: any) {
            const rx = new RegExp(filterValue.toString(), 'i');
            return rx.test(cellValue.toString());
        },
    },
    ...noDataFilterOptions,
];

const numberFilterOptions = [
    'empty',
    'equals',
    'notEqual',
    'lessThan',
    'lessThanOrEqual',
    'greaterThan',
    'greaterThanOrEqual',
    'inRange',
    ...noDataFilterOptions,
];

function getFieldColumnDef({
    key,
    type,
    fieldType,
    gridOptions,
    apiUrl,
}: {
    key: string;
    type: string;
    fieldType: string;
    gridOptions: any;
    apiUrl: string;
}): ColDef {
    const result: ColDef = { headerName: key };
    result.colId = key;
    result.headerName = key;
    result.field = key;
    result.enableRowGroup = true;
    result.enableValue = true;

    if (type === 'int' || type === 'float') {
        result.type = 'numericColumn';
        result.filter = 'agNumberColumnFilter';
        result.filterParams = { filterOptions: numberFilterOptions };
    } else if (type === 'bool') {
        result.type = 'rightAligned';
        result.filter = 'booleanFilter';
        result.cellClass = 'booleanType';
        result.cellRenderer = 'booleanCellRenderer';
        result.cellStyle = { 'text-align': 'center' };
    } else {
        result.filter = 'agTextColumnFilter';
        result.filterParams = { filterOptions: textFilterOptions };
    }

    if (gridOptions && gridOptions['filter_params']) {
        const filterParams = gridOptions['filter_params'];
        if (filterParams['type'] === 'set' && filterParams['values']) {
            result.filter = 'agSetColumnFilter';
            result.filterParams = { values: filterParams['values'] || [] };
        }
    }

    if (fieldType === 'typeCurrencySymbol') {
        result.valueFormatter = params => convertCurrencyCodeToSymbol(params.value);
    } else if (fieldType === 'typeCurrency') {
        result.valueFormatter = params => formatThousands(params.value);
    }

    result.onCellValueChanged = async (arg: ValueSetterParams) => {
        if (arg.node) {
            const field = arg.colDef.field!;
            const old = { ...arg.node.data, [field]: arg.oldValue };
            const rowIndex = arg.node.rowIndex;
            const updated = { [field]: arg.newValue };
            try {
                const response = await fetch(apiUrl, {
                    // with server-side pagination, we use POST to get the data and PUT to edit it.
                    method: 'PUT',
                    body: JSON.stringify({ rowIndex, old, updated }),
                    headers: { 'Content-Type': 'application/json' },
                });
                if (!response.ok) {
                    console.log('Error while updating value: ');
                    console.log(`Error ${response.status}: ${response.statusText}`);

                    // If the update failed, revert the value in the grid table
                    arg.node.setData({ ...arg.node.data, [arg.colDef.field!]: arg.oldValue });
                    return;
                }

                const resJson = await response.json();
                if (resJson.error) {
                    alert(`Error while updating value: ${resJson.error}`);
                    console.log(`Error while updating value: ${resJson.error}`);
                    arg.node.setData({ ...arg.node.data, [arg.colDef.field!]: arg.oldValue });
                    return;
                }

                console.log(
                    'Successfully updated cell value in Jupyter: ' +
                        JSON.stringify({ [arg.colDef.field!]: arg.newValue })
                );
            } catch (err) {
                console.log('Error while updating value: ', err);
                // If the update failed, revert the value in the grid table
                arg.node.setData({ ...arg.node.data, [arg.colDef.field!]: arg.oldValue });
            }
        }
    };

    return result as ColDef;
}

function getColumnDefs({
    apiUrl,
    columns,
}: {
    apiUrl: string;
    columns: Array<{ name: string; type: string; fieldType: string; grid_options?: any; groups?: string[] }>;
}): ColDef[] {
    if (!columns || columns.length === 0) {
        return [];
    }

    return columns.map(col => {
        const colDef = getFieldColumnDef({
            key: col.name,
            type: col.type,
            fieldType: col.fieldType,
            gridOptions: col.grid_options || {},
            apiUrl,
        });
        colDef.groups = col.groups || [];
        return colDef;
    });
}

export { getColumnDefs };
